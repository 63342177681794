import React, { useEffect } from 'react';
import * as api from '../../../api';
import { getErrorMsgByName } from '../../../common/getErrorMsgByName';
import { getNewErrorList } from '../../../common/getNewErrorList';
import { closeNotification, setNotificationMsg } from '../../../common/notificationHelper';
import * as commonconstants from '../../../constants/common-constants';
import { fetchErrorMsg } from '../../../constants/common-constants';
import * as constants from '../../../constants/new-situation-constants';
import { FormError } from '../../../models/step';
import { MyAppointmentElement, ElementHelpText, MessageElement } from '../../../models/umbracoElement';
import AppointmentList from '../AppointmentList/appointment-list';
import CustomModal from '../../shared/CustomModal/modal';
import TopTitle from '../../shared/TopTitle/top-title';
import OkCancelButtons from '../../shared/OkCancelButtons/ok-cancel-buttons';
import HelperIcon from '../../shared/HelperIcon/helper-icon';
import FlowButton from '../../shared/Buttons/FlowButton/flow-button';
import ButtonZone from '../../shared/ButtonZone/button-zone';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { defaultMessageModel } from '../../../models/messageModel';
import { requiredErrorMsg } from '../../../constants/common-constants';
import { getDateStr } from '../../../common/dateutil'
import InformationBox from '../../shared/InformationBox/information-box';
import InputWrapper from '../../shared/InputWrapper/input-wrapper';
import { NotificationBarType } from '../../shared/NotificationBarSetting/notification-bar-setting';
import OnePageFormWrapper from '../../shared/OnePageFormWrapper/one-page-form-wrapper';
import Wrapper, { NotificationMessage } from '../../shared/Wrapper/wrapper';
import style from './new-appointment-bookings.module.scss';
import messageStyle from '../../Message/message.module.scss';
interface NewAppointmentBookingProps {
    memberid: string;
    elementdata: MyAppointmentElement;
    appointments: api.Appointment[];
    setIsOpen: (isOpen: boolean, reload: boolean, successfulBooking: boolean, failedBooking: boolean) => void;
    data?: string;
}

function NewAppointmentBooking(props: NewAppointmentBookingProps) {
    const [isBookModalOpen, setBookModalOpen] = React.useState(false);
    const [appointmentToBook, setAppointmentToBook] = React.useState<api.Appointment | undefined>();
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [loading, setLoading] = React.useState(false);
    const editorRef = React.useRef<Editor>(null);
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty(),);
    const [message, setMessage] = React.useState<api.Message>(defaultMessageModel);
    const [data] = React.useState<MessageElement>(props.data ? JSON.parse(props.data) : undefined);
    const blurdivRef = React.useRef<(HTMLInputElement | null)>();
    const [showTooLongMsg, setShowTooLongMsg] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [notification, setNotification] = React.useState<NotificationMessage | undefined>();
    const [isReadonly, setIsReadonly] = React.useState(false);

    const openBookModal = (appointment: api.Appointment) => {
        setAppointmentToBook(appointment);
        setBookModalOpen(true);
    }
    const closeBookModal = () => {
        setAppointmentToBook(undefined);
        setBookModalOpen(false)
    }

    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        setErrorList(newErrorlist);

    }

    const close = (reload: boolean, successfulBooking: boolean, failedBooking: boolean) => {
        props.setIsOpen(false, reload, successfulBooking, failedBooking);
    }


    const openDetail = (appointment: api.Appointment) => {
        setIsReadonly(true);
        openBookModal(appointment);
    }

    const book = async () => {
        setLoading(true);
        let bookAppointmentRequest: api.BookAppointment = { appointmentId: appointmentToBook?.id, message: message.content!} 
        //First call API to check availability of appointment, and then book it.
        await api.MembersService.membersBookAppointment(props.memberid, bookAppointmentRequest).then(() => {
            setLoading(true);
            close(true, true, false);
        })
            .catch((error) => {
                close(true, false, true);
            })
            .finally(() => {
                setLoading(false);
                closeBookModal();
            });;
    }

    const wrapperProps: { available: ElementHelpText } = React.useMemo(() => {
        const base = {
            contentTypeAlias: "",
            id: ""
        }
        return {
            available: {
                ...base,
                title: props.elementdata.availableAppointmentsTitle
            }
        }
    }, [props.elementdata])

    const onClickEditorDiv = () => {
        editorRef.current?.focusEditor();
    }

    const editorError = React.useMemo(() => getErrorMsgByName(errorList, "content"), [errorList])

    const replacer = (_: string, value: string) => {
        return value.replace(/\r?\n|\r/g, " ");
    }

    const onEditorChange = (draftEditorState: EditorState) => {
        setEditorState(draftEditorState)

        var content = draftEditorState.getCurrentContent();
        const isEditorEmpty = !content.hasText();
        const currentPlainText = content.getPlainText();
        const lengthOfTrimmedContent = currentPlainText.trim().length
        const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
        let toolong = false;
        let msgtxt = ""
        if (!isContainOnlySpaces) {
            // replacing new line to space. 
            msgtxt = JSON.stringify(draftEditorState.getCurrentContent().getPlainText(), replacer);
            // removing "" that are added by stringify 
            msgtxt = msgtxt.substring(1, msgtxt.length - 1);
            // prevent having more than 100 characters
            if (msgtxt.length > 200) {
                msgtxt = msgtxt.substring(1, 200);
                toolong = true;
            }
            setModel("content", msgtxt);
            setError("content", "")
        }
        else {
            setError("content", data?.contentNotIncluded || requiredErrorMsg)
            setModel("content", undefined);
        }
        if (toolong) {
            /** text is too long. Set previous state and blur from editor.
             *  (as editorref has no blue, we set focus on invisible input)
             *  by bluring, editor can show the text of the previous state.
             */
            setEditorState(editorState);
            setTimeout(() => blurdivRef.current?.focus());
            setTimeout(() => editorRef.current?.focusEditor());

        }
        setShowTooLongMsg(msgtxt.length > 200);
    }

    const setModel = (name: string, value: string | boolean | object | undefined) => {

        let newMessage: api.Message | undefined = message ? {
            ...message,
        } : defaultMessageModel;
        if (newMessage) {
            (newMessage as any)[name] = value;
            setMessage(newMessage);
        }
    }

    return (<>
        <Wrapper properties={{ id: "", contentTypeAlias: "", title: "" }} loading={loading} notification={notification}></Wrapper>
            {!isBookModalOpen ? <Wrapper properties={{ id: "", contentTypeAlias: "", title: "" }}
                loading={loading}
                notification={notification}
                backBarSettings={props.elementdata.showBackBar ? { backLabel: props.elementdata.cancelButtonLabel || "Annuller", onBackClick: () => props.setIsOpen(false, false, false, false) } : undefined}
                bottomZone={<ButtonZone twoButtons={!props.elementdata.showBackBar} className={`${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]}`}>
                    {!props.elementdata.showBackBar && !loading && <FlowButton isBack={true} onClick={() => {
                        closeNotification();
                        props.setIsOpen(false, false, false, false);

                    }
                    }>{"Annuller"}</FlowButton>}
                </ButtonZone>}
            >
                {
                    props.appointments && props.appointments.length > 0 ? <Wrapper
                        loading={loading}
                        //notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}
                        children={!loading &&
                            <AppointmentList appointments={props.appointments} showButtonLabel={props.elementdata.showButtonLabel} openCancel={() => { }} openDetail={openDetail} ></AppointmentList>
                        }
                        properties={wrapperProps.available} ></Wrapper> : 
                        <div style={{ paddingTop: 55, paddingBottom: 55 }}>
                        <TopTitle titletext={"Der er desværre ingen ledige samtaler"} />
                        <div> <p>Der er desværre ingen ledige rådgivningstider lige nu. Du kan prøve igen på et senere tidspunkt.</p></div>
                        </div>

                }
            </Wrapper> :
            <CustomModal isModalOpen={isBookModalOpen} onCancelClick={closeBookModal}>
                <div className={style.elementcontainer}>
                    <div style={{ paddingTop: 55 }}></div>
                    <TopTitle titletext={"Bekræft booking af samtale"} helpIcon={appointmentToBook?.counselor?.name ? <HelperIcon helptext={"Bekræft den valgte rådgiver og tidspunkt. Hvis ønsket, kan du tilføje en beskrivelse."} /> : undefined} />
                    <div className={style.step}>
                        <span className={style.bold}>Rådgiver:</span>
                        <div dangerouslySetInnerHTML={{ __html: appointmentToBook?.counselor?.name || "" }} />
                        <span className={style.bold}>Tidspunkt:</span>
                        <div dangerouslySetInnerHTML={{ __html: getDateStr(new Date(appointmentToBook?.date!), true) || "" }} />
                        <span className={style.bold}>Beskrivelse:</span>
                    </div>
                    <form className={messageStyle.textfieldbot}>
                        <div className={`${messageStyle.editor} ${editorError ? messageStyle.erroreditor : ""}`} onClick={onClickEditorDiv} >
                            <Editor
                                ref={editorRef}
                                editorState={editorState}
                                onEditorStateChange={onEditorChange}
                                toolbar={{
                                    options: ['history'],
                                }}
                            />
                        </div>
                        {(showTooLongMsg ? <div className={`${messageStyle.errorMessage} ${messageStyle.inputinformationtxt} ${messageStyle.toolongmsg}`}>(Beskeden må ikke være længere end 200 tegn)</div>
                            : <div className={`${messageStyle.inputinformationtxt}`}>(Maks. 200 tegn)</div>)}
                        {editorError && <div className={`${messageStyle.errorMessage} ${messageStyle.toolongmsg}`}>{editorError}</div>}
                    </form>
                    <OkCancelButtons
                        cancelLabel={"Afbryd"}
                        loading={loading}
                        okLabel={"Bekræft booking"}
                        onClickCancel={closeBookModal}
                        onClickOk={book}
                        okDisabled={false} />
                </div>
            </CustomModal>
            }
    </>)
}
export default NewAppointmentBooking;