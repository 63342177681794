import React, { useEffect } from 'react';
import * as api from '../../api';
import { ContingentElement } from '../../models/umbracoElement';
import Wrapper from '../shared/Wrapper/wrapper';
interface BalanceProps {
    memberid: string;
    data: any;
}

function Balance(props: BalanceProps) {
    const [currentBalanceInfo, setBalanceInfo] = React.useState<api.MemberBalance>();
    const elementdata: ContingentElement = JSON.parse(props.data);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getBalanceData();
        }
        call().catch((err) => { }).finally(() => { setLoading(false) });
    }, []);

    const getBalanceData = async () => {
        var balance: api.MemberBalance = await api.MembersService.getMembersBalance(props.memberid);

        if (balance != null) {
            setBalanceInfo(balance);
        }
    }

    return (
        <Wrapper properties={elementdata} loading={loading}>
           { !loading && 
           <> 
                {currentBalanceInfo?.balance &&
                <> 
                    {(currentBalanceInfo?.balance)+" kr."}
                </> 
                }
           </> 
           }   
        </Wrapper>
    )
}
export default Balance;